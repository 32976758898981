exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agendar-coleta-tsx": () => import("./../../../src/pages/agendar-coleta.tsx" /* webpackChunkName: "component---src-pages-agendar-coleta-tsx" */),
  "component---src-pages-cadastro-tsx": () => import("./../../../src/pages/cadastro.tsx" /* webpackChunkName: "component---src-pages-cadastro-tsx" */),
  "component---src-pages-educacao-tsx": () => import("./../../../src/pages/educacao.tsx" /* webpackChunkName: "component---src-pages-educacao-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jornada-tsx": () => import("./../../../src/pages/jornada.tsx" /* webpackChunkName: "component---src-pages-jornada-tsx" */),
  "component---src-pages-logistica-reversa-tsx": () => import("./../../../src/pages/logistica-reversa.tsx" /* webpackChunkName: "component---src-pages-logistica-reversa-tsx" */)
}

